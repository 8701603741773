import React, { useState, useEffect } from "react";
import "./Deposit.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { confirmDeposit } from "../store";
import { showNotification } from "../components/NotificationProvider";

import PaymentGateway from "../components/PaymentGateway";

const Deposit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [depositInfo, setDepositInfo] = useState(null);
  const [_token, setToken] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (!token) {
      navigate("/warning");
    } else {
      console.log("Token:", token);
      setToken(token);
      dispatch(confirmDeposit({ token }))
        .unwrap()
        .then((res) => {
          console.log(res);
          if (res && res.status === "success") {
            setIsAuthenticated(true);
            showNotification(
              "Success",
              "success",
              res.message || "Deposit creation permission granted"
            );
            // const { amount, cvc, expiry, name, number } = res.data.cardInfo;
            // setDepositInfo({ amount, cvc, expiry, name, number });

            const { userId, name, lastName } = res.data;
            setDepositInfo({ userId, name, lastName });
          }
        })
        .catch((err) => {
          console.log(err);

          if (err && err.response === "Invalid token") {
            navigate("/warning");
            showNotification(
              "Error",
              "danger",
              "Deposit confirmation failed, please try again"
            );
          }
        })
        .finally(() => {});
    }
  }, [location, navigate, dispatch]);

  const renderComponent = () => {
    if (isAuthenticated) {
      return <PaymentGateway readyInfo={depositInfo} token={_token} />;
    }
    return <h1>Authenticating...</h1>;
  };

  return <div className="main-container">{renderComponent()}</div>;
};

export default Deposit;
